.textbox {
  text-align: center;
}

.text-link{
  color: dodgerblue;
  cursor: pointer;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.wrap {
  flex-wrap: wrap;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.width-100{
  width:100%;
}

.width-50{
  width:50%;
}

.width-25{
  width:25%;
}

.width-65{
width:65%;
}

.width-35{
  width:35%;  
}

.width-45{
  width: 45%;
}

.font-normal {
  font-size:15px;
}

.item-center-align{
  align-items: center;
}

.item-end-align{
  align-items: flex-end;
}
/* .info-text{
  width:140%;
  background-color: red;
} */
.react-html5-camera-photo>img, .react-html5-camera-photo>video{
  width:360px !important;
}

.div-center {
  margin:0 auto;
}

.overflow-hidden {
  overflow-x: hidden;
}

.padding-top-20 {
  padding-top: 20px;
}

.header {
  border-bottom: 1px solid rgb(223, 227, 233);
  -webkit-box-shadow: 0px 3px 10px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 3px 10px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 3px 10px -3px rgba(0,0,0,0.75);
  width:100%;
  padding: 7px 15px;
}

.paper {
  border:1px solid;
  max-width:380px;
  /* height:150px; */
  padding-bottom: 10px;
}

.paper-fluid {
  border:1px solid;
  width:92%;
  height:100%;
  margin: 15px;
  padding: 10px;
}

.main{
  margin-top:15px;
}

.text-box-label {
  color: #7f8fa4;
  margin-bottom: 0px;
}

.text-box-desc {
  color: #7f8fa4;
  font-size: 14px;
}

.text-box-wrapper {
  /* max-height:72px; */
  text-align:left;
  width: 100%;
  margin-right:10px;
  position: relative;
}

.outer-container{
  display: flex;
  flex-direction: row;
}

.inner-container {
  padding: 2% 2.5%;
}

.inner-container-static {
  padding: 10px 15px;
}

.inner-container-dropdown {
  padding: 0% 2.5%;
  display: flex;
  justify-content: flex-end;
}

.metro-logo{
  width: 120px;
  height: 45px;
}

.chqbook-logo-div {
  text-align: right;
  padding:5px;
}
.chqbook-logo{
width:140px;
}

.metro-logo-div {
  padding:5px;
}

.header-text {
  text-align: center;
  font-size: 22px;
}
/* Approval Container Page CSS Start */
.box-2 {
  align-self: center;
  text-align: start;
}

.info-div {
  text-align: right;
  width: 50%;
}

/* Approval Container Page CSS End */

/*Admin Container Page CSS Start */

.heading {
  padding: 15px;
  font-size: 20px;
  font-weight: bold;
}

.link {
  color:#2196f3;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.fluid-width-45 {
  width: 45%;
}

.fluid-width-50 {
  width: 50%;
}

.fluid-width-70 {
  width: 70%;
}

.fluid-width-img {
  width: 28%;
}

.horizontal-to-vertical {
  display: flex;
  flex-direction: row;
}

/*Admin Container Page CSS End */

/*Membership Container Page CSS Start */
.form-error-text{
  font-size: 12px;
  color: rgb(244, 67, 54);
}

.form-success-text{
  font-size: 12px;
  color: green;
}

.membership-msg {
  position: relative;
  bottom: 20px;
  padding: 1% 2.5% 0% 2.5%;
}

.tnc-text {
  font-size:9px;
}

.padding-side {
  padding: 0% 2.5%
}

.upload{
  padding: 10px;
}

.tick{
  color:green;
  font-size:24px
}

.loader{
  position: absolute;
  right: 10%;
  top: 10%;
}

/*Membership Container Page CSS End */

/*Upload files feature starts */
.sample-download{
  font-size: 1em;
  width: 27px;
  height: 26px;
  padding: 0;
  border: none;
  will-change: transform,opacity;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
  background-color: rgba(0,0,0,.5);
  box-shadow: 0 0 0 0 hsla(0,0%,100%,0);
  transition:.25s ease-out;
}

.sample-download:focus{
  outline: none;
}

.sample-download:hover{
  width: 30px;
  height: 29px;
}

.sample-download-text{
  font-size: 14px;
  opacity: .5;
  transition: opacity .25s ease-in-out;
}
/*Upload files feature end */

/* Pagination Styles start */
.prev {
  padding-right: 20px;
  cursor: pointer;
}
a, a:active, a:focus{
  outline: none; /* Works in Firefox, Chrome, IE8 and above */ 
}
a:hover{
  text-decoration: none;
  color: darkblue;
}
.next {
  padding-left: 20px;
  cursor: pointer;
}
.active-page {
  border: 1px solid;
}
.all-page {
  padding: 0px 5px 0px 5px;
  cursor: pointer;
}
.break-me{
  cursor: pointer;
}
/* Pagination Styles end */

.experian-consent{
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.exp-img-section{
  align-items: center;
  align-self: center;
  font-size: 12px;
}

/* Image Thumbnail */

.thumbnail {
  border: 1px solid #ddd; /* Gray border */
  border-radius: 4px;  /* Rounded border */
  padding: 5px; /* Some padding */
  width: 230px; /* Set a small width */
  margin: 10px;
  min-height: 150px;
  cursor: pointer;
}

/* Add a hover effect (blue shadow) */
.thumbnail:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 900px) {
  .outer-container{
    display: flex;
    flex-direction: column;
  }
  .text-box-label {
    color: #7f8fa4;
    margin-bottom: 0px;
    font-size:15px;
  }
  .hidden-mobile{
    display: none;
  }

  .text-box-desc {
    color: #7f8fa4;
    font-size: 12px;
  }
  .metro-logo{
    width:80px;
    height: 35px;
    }
  .chqbook-logo{
      width:100px;
    }
  .header-text {
    text-align: center;
    font-size: 14px;
  }

  .fluid-width-45 {
    width: 100%;
  }

  .fluid-width-50 {
    width: 100%;
  }

  .inner-container-dropdown {
    padding: 0% 2.5%;
    display: flex;
    justify-content: flex-start;
  }

  .horizontal-to-vertical {
    display: flex;
    flex-direction: column;
  }

  .react-html5-camera-photo>img, .react-html5-camera-photo>video{
    width:100% !important;
  }

  .fluid-width-img {
    width: 100%;
  }

  .item-center-align-mobile{
    align-items: center;
  }
  .fluid-width-70 {
    width: 100%;
  }

  .font-normal {
    font-size:13px;
  }
} 

@media only screen and (min-width: 901px) {
  .hidden-desktop{
    display:none;
  }
} 
