
html {
  width: 100%;
  height: 100%;
}

body {
  font-family: sans-serif;
  overflow-x:hidden;
}

h1 {
  font-size:30px;
}

h2 {
  font-size:20px
}


.flex-vertical {
  display: flex;
  flex-direction: column;
}

.flex-horizontal {
  display: flex;
  flex-direction: row;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.a-center, .flex-center {
  align-items: center;
}

.a-end {
  align-items: flex-end;
}

.j-end {
  justify-content: flex-end;
}

.j-center, .flex-center {
  justify-content: center;
}

.j-around {
  justify-content: space-around;
}

.j-between {
  justify-content: space-between;
}

.j-evenly {
  justify-content: space-evenly;
}

.a-self-center {
  align-self: center;
}

.no-shrink {
  flex-shrink: 0;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

.flex-7 {
  flex: 7;
}

.white-container {
  background-color: #fff;
  border: 1px rgba(0, 0, 0, 0.12) solid;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.12);
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
}